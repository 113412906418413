import * as turf from "@turf/turf";
const { validateField, validateGrid } = require("@origino-io/fieldvalidations");

export const fieldsValidations = (value, validations, options = {}) => {
  if(options.fieldType === 'checkbox' || options.fieldType === 'multiselectbox' || options.fieldType === 'groupedmultiselectbox')
    return validationMultiSelectBox(value, validations);

  if(options.fieldType === 'text' && options.fieldName === 'company_email')
    return validationEmail(value)

  if(options.fieldType === 'file'){
    return (validations.mandatory?.value && !value) ? [validations.mandatory?.message] : [];
  }

  if(options.fieldType === 'autocomplete'){
    return (validations.mandatory?.value && !value) ? [validations.mandatory?.message] : [];
  }

  if(options.fieldType === 'location' && validations.inPolygon?.value){
    let point = value && JSON.parse( value ).geometry.coordinates
    return validateLocation(options.polygon, point) ? [] : [validations.inPolygon?.message];
  }

  return validateField(value, validations, options)
}

const validateLocation = (polygon, pointCoords, bufferDistanceKm = 5) => {
  if (pointCoords) {
    const position = turf.point(pointCoords)
    const bufferedPolygon = turf.buffer(polygon, bufferDistanceKm, { units: 'kilometers' })
    return turf.booleanPointInPolygon(position, bufferedPolygon.geometry)
  } else {
    return false
  }
}

const validationMultiSelectBox = (value, validations) => {
  return (validations.mandatory && !value) ? [validations.mandatory?.message] : []
}

const validationEmail = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (value && !regex.test(value)) ? ['Complete correctamente el Email'] : []
}

export const validateGridWithFiles = async (assets, fields, fieldsFiltered, options) => {
  let { errorCount, listErrorsId, resultErrors, resultUniques } =
  await validateGrid(assets, fieldsFiltered, options);

  for(let row = 0; row < assets.length; row++) {
    for (const field of fieldsFiltered) {
      if(field.type === 'location'){
        const hasErrors = fieldsValidations(
          assets[row][field.name],
          field.validations,
          {
            fieldType: field.type,
            polygon: options.availableGeolocations[`_${assets[row].id}`]
          })
        if(hasErrors.length !== 0) {
          const error = {row: row, column: field.name, errors: hasErrors}
          if(!resultErrors.some(err =>
            err.row === error.row
            && err.column === error.column
          )){
            listErrorsId.push(assets[row].id);
            resultErrors.push(error);
            errorCount += 1;
          }
        }
      }
    }
    for(const field of fields) {
      if(field.type === 'file'){
        const hasErrors = fieldsValidations(
          assets[row][field.name],
          field.validations,
          {
            fieldType: field.type
          }
        )
          if(hasErrors.length !== 0) {
            const error = {row: row, column: field.name, errors: hasErrors}
            if(!resultErrors.some(err =>
              err.row === error.row
              && err.column === error.column
            )){
              listErrorsId.push(assets[row].id);
              resultErrors.push(error);
              errorCount += 1;
            }
          }
      }
    }
  }
  return {listErrorsId, resultErrors, errorCount, resultUniques};
}